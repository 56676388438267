
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { UserRegister } from '@/entities/Auth';
import { useAuthStore } from '@/stores/auth/AuthStore';
import Alert from '@/misc/Alert';

export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();

    const formRegister = reactive<UserRegister>({
      username: '',
      password: '',
      sender_name: '',
      sender_phone: '',
      bank_name: '',
      bank_account_name: '',
      bank_account_number: '',
    });

    const register = (): void => {
      authStore.setRegister(formRegister).then(()=>{
        authStore.setLogin(formRegister).then(()=>{
          router.push('/')
        }).catch((err) => {
          Alert.Error({title: "Login", message: err})
        })
      }).catch((err) => {
          Alert.Error({title: "Sign Up", message: err})
      })
    };

    return {
      formRegister,
      register,
    };
  },
});
